import React from "react";
import "./App.css";
import moment from "moment";
import "moment/locale/pt-br";
import jones_photo from "./asset/jones_photo.png";
import thami_photo from "./asset/thami_photo.png";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";

import { Timeline, TimelineEvent } from "react-event-timeline";

function App() {
  var currentTime = moment();

  const planJones = [
    {
      period: "CAFÉ DA MANHA",
      emoji: "☕️",
      timeString: "Entre 08:00 e 09:00",
      timeRange: {
        start: moment().startOf("day").hours(8).minutes(0),
        end: moment().startOf("day").hours(9).minutes(0),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "Leite Desnatado",
          amount: "200ml",
        },
        {
          name: "café com adoçante",
          amount: "50ml",
        },
        {
          name: "pão",
          amount: "1 fatia",
        },
        {
          name: "ovo",
          amount: "2 unidades",
        },
        {
          name: "manteiga",
          amount: "1 colher de chá",
        },
        {
          name: "fruta",
          amount: "1 (banana ou mamão)",
        },
        {
          name: "aveia",
          amount: "1 colher rasa de sopa",
        },
      ],
    },
    {
      period: "LANCHE DA MANHÃ",
      emoji: "🍎",
      timeString: "Entre 10:30 e 11:00",
      timeRange: {
        start: moment().startOf("day").hours(10).minutes(30),
        end: moment().startOf("day").hours(11).minutes(0),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "fruta",
          amount: "1 (banana ou mamão)",
        },
      ],
    },
    {
      period: "ALMOÇO",
      emoji: "🍽",
      timeString: "Entre 12:00 e 13:30",
      timeRange: {
        start: moment().startOf("day").hours(12).minutes(0),
        end: moment().startOf("day").hours(13).minutes(30),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "arroz",
          amount: "3 colheres de sopa",
        },
        {
          name: "feijão (roxinho)",
          amount: "3 colheres de sopa	",
        },
        {
          name: "carne magra",
          amount: "240g	",
        },
        {
          name: "legumes refogado",
          amount: "4  colheres de sopa	",
        },
        {
          name: "salada de folha",
          amount: "a vontade",
        },
        {
          name: "sobremesa",
          amount: "1 fruta pequena",
        },
        {
          name: "suco ou água",
          amount: "após a refeição	",
        },
      ],
    },
    {
      period: "LANCHE DA TARDE",
      emoji: "🥛",
      timeString: "Entre 15:00 e 16:00",
      timeRange: {
        start: moment().startOf("day").hours(15).minutes(0),
        end: moment().startOf("day").hours(16).minutes(0),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "Vitaminado/iogurte desnatado",
          amount: "200ml	",
        },
        {
          name: "fruta",
          amount: "1 (banana ou morango)",
        },
      ],
    },
    {
      period: "JANTAR	",
      emoji: "🥗",
      timeString: "Entre 18:30 e 19:30",
      timeRange: {
        start: moment().startOf("day").hours(18).minutes(30),
        end: moment().startOf("day").hours(19).minutes(30),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "carne magra",
          amount: "240g",
        },
        {
          name: "legumes refogado",
          amount: "5  colheres de sopa",
        },
        {
          name: "salada de folha",
          amount: "a vontade",
        },
        {
          name: "sobremesa",
          amount: "1 fruta pequena",
        },
        {
          name: "suco ou água",
          amount: "após a refeição",
        },
      ],
    },
    {
      period: "LANCHE DA NOITE",
      emoji: "🍵",
      timeString: "Entre 21:30 e 23:00",
      timeRange: {
        start: moment().startOf("day").hours(21).minutes(30),
        end: moment().startOf("day").hours(23).minutes(0),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "chá com adoçante/yakult light",
          amount: "a vontade (camomila/erva doce)",
        },
      ],
    },
  ];

  const planThami = [
    {
      period: "CAFÉ DA MANHA",
      emoji: "☕️",
      timeString: "Entre 07:30 e 08:30",
      timeRange: {
        start: moment().startOf("day").hours(7).minutes(30),
        end: moment().startOf("day").hours(8).minutes(30),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "Leite Desnatado",
          amount: "200ml",
        },
        {
          name: "Café descafeinado com adoçante",
          amount: "50ml",
        },
        {
          name: "Pão integral",
          amount: "1 unidade",
        },
        {
          name: "Requeijão light",
          amount: "1 colher de chá",
        },
      ],
    },
    {
      period: "LANCHE DA MANHÃ",
      emoji: "🍎",
      timeString: "Entre 10:30 e 11:00",
      timeRange: {
        start: moment().startOf("day").hours(10).minutes(30),
        end: moment().startOf("day").hours(11).minutes(0),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "fruta",
          amount: "1 (banana ou mamão)",
        },
        {
          name: "ômega 3",
          amount: "1 unidade",
        },
        {
          name: "remédios",
          amount: "1 unidade cada",
        },
      ],
    },
    {
      period: "ALMOÇO",
      emoji: "🍽",
      timeString: "Entre 12:00 e 13:30",
      timeRange: {
        start: moment().startOf("day").hours(12).minutes(0),
        end: moment().startOf("day").hours(13).minutes(30),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "arroz",
          amount: "3 colheres de sopa",
        },
        {
          name: "feijão (roxinho)",
          amount: "2 colheres de sopa	",
        },
        {
          name: "carne magra",
          amount: "120g	",
        },
        {
          name: "legumes refogado",
          amount: "4  colheres de sopa	",
        },
        {
          name: "salada de folha",
          amount: "a vontade",
        },
        {
          name: "sobremesa",
          amount: "2 quadradinhos chocolate",
        },
        {
          name: "fruta",
          amount: "laranja ou tangerina",
        },
      ],
    },
    {
      period: "LANCHE DA TARDE",
      emoji: "🥛",
      timeString: "Entre 15:00 e 16:00",
      timeRange: {
        start: moment().startOf("day").hours(15).minutes(0),
        end: moment().startOf("day").hours(16).minutes(0),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "Vitaminado",
          amount: "200ml / (banana ou morango)	",
        },
        {
          name: "Pão integral",
          amount: "1 unidade",
        },
        {
          name: "Requeijão light",
          amount: "1 colher de chá",
        },
      ],
    },
    {
      period: "JANTAR	",
      emoji: "🥗",
      timeString: "Entre 18:30 e 19:30",
      timeRange: {
        start: moment().startOf("day").hours(18).minutes(30),
        end: moment().startOf("day").hours(19).minutes(30),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "arroz",
          amount: "3 colheres de sopa",
        },
        {
          name: "feijão (roxinho)",
          amount: "2 colheres de sopa	",
        },
        {
          name: "carne magra",
          amount: "120g	",
        },
        {
          name: "legumes refogado",
          amount: "4  colheres de sopa	",
        },
        {
          name: "salada de folha",
          amount: "a vontade",
        },
        {
          name: "sobremesa",
          amount: "2 quadradinhos chocolate",
        },
        {
          name: "fruta",
          amount: "laranja ou tangerina",
        },
      ],
    },
    {
      period: "LANCHE DA NOITE",
      emoji: "🍵",
      timeString: "Entre 21:30 e 23:00",
      timeRange: {
        start: moment().startOf("day").hours(21).minutes(30),
        end: moment().startOf("day").hours(23).minutes(0),
      },
      backgroundColor: "#adb5bd",
      Lunch: [
        {
          name: "chá com adoçante",
          amount: "a vontade (camomila/erva doce)",
        },
      ],
    },
  ];

  const pathPage = window.location.pathname.split("/");
  const isThamiPLan = pathPage.length > 1 && pathPage[1] === "thami";
  const currentPlanNutri = isThamiPLan ? planThami : planJones;

  var returnCurrentPerson = () => {
    return isThamiPLan
      ? {
          name: "Thamires Zamai",
          photo: thami_photo,
          url: "/thami",
        }
      : {
          name: "Jones Carvalho",
          photo: jones_photo,
          url: "/",
        };
  };

  return (
    <>
      <center>
        <br />
        <Card
          style={{
            width: "15rem",
          }}
        >
          <CardBody>
            <CardTitle tag="h5">{returnCurrentPerson().name}</CardTitle>
            <CardSubtitle className="mb-1 text-muted" tag="h6">
              {
                <div style={{ fontSize: "12px" }}>
                  {"Plano de nutrição - " + moment().format("DD/MM/YYYY")}
                </div>
              }
            </CardSubtitle>
          </CardBody>
          <center>
            <img
              alt={returnCurrentPerson().name}
              src={returnCurrentPerson().photo}
              width="60%"
              style={{ padding: "1px", marginBottom: "8px" }}
            />
          </center>
          <br />
          <Button
            onClick={() => {
              if (!isThamiPLan)
                window.location.href = window.location.href + "thami";
              else {
                window.location.href = window.location.origin;
              }
              
            }}
          >
            {isThamiPLan ? "Plano Jones" : "Plano Thamires"}
          </Button>
        </Card>
      </center>
      <div style={{ padding: "1rem", visibility: "visible" }}>
        <Timeline>
          {currentPlanNutri.map((plan, index) => {
            return (
              <TimelineEvent
                key={index}
                title={plan.period}
                subtitle={plan.timeString}
                subtitleStyle={{ color: "white" }}
                icon={<i className="material-icons md-18">{plan.emoji}</i>}
                container="card"
                cardHeaderStyle={{ backgroundColor: "#adb5bd", color: "white" }}
                style={
                  currentTime.isBetween(
                    plan.timeRange.start,
                    plan.timeRange.end
                  )
                    ? {
                        boxShadow: "0 0 6px 0.1px #198754",
                        border: "1px solid #5c5c5c",
                        borderRadius: 3,
                      }
                    : { border: "1px solid #5c5c5c", borderRadius: 3 }
                }
              >
                <Table striped>
                  <thead>
                    <tr>
                      <th style={{width: "50%"}}>Itens</th>
                      <th style={{width: "50%"}}>Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plan.Lunch.map((lunch, index) => {
                      return (
                        <tr key={index}>
                          <td>{lunch.name}</td>
                          <td>{lunch.amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TimelineEvent>
            );
          })}
        </Timeline>
      </div>
    </>
  );
}

export default App;
